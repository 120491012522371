const YES_NO = Object.freeze({
    yes: {text: 'Sim', value: "3"},
    no: {text: 'Não', value: "0"}
});

const YES_NO_SOMETIMES = Object.freeze({
    yes: {text: 'Sim, todos os dias', value: "3"},
    sometimes: { text: 'Sim, de vez em quando', value: "1"},
    no: {text: 'Nunca', value: "0"}
});

const FREQUENCIA = Object.freeze({
    many: {text: 'Sim, mais de 1x ao dia', value: "5"},
    everyday: {text: 'Sim, todos os dias', value: "3"},
    rarely: { text: 'Sim, de vez em quando', value: "1"},
    no: {text: 'Nunca', value: "0"}
});

const FREQ_YEAR = Object.freeze({
    many: {text: 'Sim, várias vezes ao ano', value: "5"},
    yes: {text: 'Sim, todos os anos', value: "3"},
    rarely: { text: 'Raramente', value: "1"},
    no: { text: 'Nunca', value: "0"}
});

const FREQ_WEEK = Object.freeze({
    many: {text: 'Sim, todos os dias', value: "5"},
    yes: {text: 'Sim, 3-5x por semana', value: "3"},
    little: {text: 'Sim, 1-2x por semana', value: "2"},
    rarely: { text: 'Raramente', value: "1"},
    no: { text: 'Nunca', value: "0"}
});

const HABITO_INTESTINAL = Object.freeze({
    constipado: {text: 'Constipado (evacua menos de 4x/semana)', value: "3 "},
    normal: {text: 'Normal (até 2x por dia)', value: "0 "},
    solto: {text: 'Solto (mais de 2x por dia)', value: "1 "},
    diarreia: {text: 'Diarréia (cocô mole, sem forma)', value: "4"}
});

const FREQ_DAY = Object.freeze({
    many: {text: '5x ou mais ao dia', value: "5"},
    normal: {text: '3 a 4x ao dia', value: "0"},
    little: {text: '1 a 2x ao dia', value: "2"}
});

const NIVEL_ATIVIDADE = Object.freeze({
    low: {text: 'Pouca atividade (tarefas básicas, sem exercícios)', value: "1"},
    medium: {text: 'Moderada (caminhadas, yoga, pilates)', value: "2"},
    high: {text: 'Muito ativa (corrida, musculação, crossfit)', value: "3"}
});

const getTextByValue = (object, value) => {
    let text = ''
    if (!value) return 'Não respondeu';

    Object.values(object).forEach(entry => {
        if (entry.value === value)
            text = entry.text
    })
    return text;
}

export {
    YES_NO,
    YES_NO_SOMETIMES,
    FREQUENCIA,
    FREQ_YEAR,
    FREQ_WEEK,
    FREQ_DAY,
    HABITO_INTESTINAL,
    NIVEL_ATIVIDADE,
    getTextByValue
}