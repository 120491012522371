import React, { useState } from 'react';
import * as S from './styled';
import RadioGroup from '../QuizForm/RadioGroup';
import FormButton from '../QuizForm/FormButton';

import { 
    YES_NO_SOMETIMES,
    FREQ_YEAR,
    HABITO_INTESTINAL,
} from './util/formOptions';

const Step2 = ({state, setValues}) => {
    const [stepValues, setStepValues] = useState(() => ({
        'cansada': state.cansada ? state.cansada : '',
        'dificil_dormir': state.dificil_dormir ? state.dificil_dormir : '',
        'ansiedade': state.ansiedade ? state.ansiedade : '',
        'compulsao': state.compulsao ? state.compulsao : '',
        'enxaqueca': state.enxaqueca ? state.enxaqueca : '',
        'doente': state.doente ? state.doente : '',
        'intestino': state.intestino ? state.intestino : '',
        'gases': state.gases ? state.gases : '',
        'azia': state.azia ? state.azia : '',
        'distensao': state.distensao ? state.distensao : '',
    }));

    
    const onChangeValue = (event) => {
        setStepValues({...stepValues, [event.name]: event.value})
    } 

    return (
        <S.StepWrapper>
            <RadioGroup 
                name="cansada"
                text="Você se sente cansada já no início do dia?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.cansada}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="dificil_dormir"
                text="Você têm dificuldade para dormir?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.dificil_dormir}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="ansiedade"
                text="Você sofre de ansiedade?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.ansiedade}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="compulsao"
                text="Tem episódios de compulsão alimentar?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.compulsao}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="enxaqueca"
                text="Sente dores de cabeça ou enxaqueca?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.enxaqueca}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="doente"
                text="Fica doente com frequência? (gripes, sinusite, diarréia...)" 
                options={FREQ_YEAR}
                checked={stepValues.doente}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="intestino"
                text="Como são seus hábitos intestinais?" 
                options={HABITO_INTESTINAL}
                checked={stepValues.intestino}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="gases"
                text="Sofre com excesso de gases?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.gases}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="azia"
                text="Tem azia?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.azia}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="distensao"
                text="Tem distensão abdominal?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.distensao}
                onChangeValue={onChangeValue}
            />
            <FormButton 
                text="Avançar" 
                type="next" 
                onClick={(e) => {
                    e.preventDefault();
                    setValues(stepValues)
                }} 
            />
        </S.StepWrapper>
    )
}
export default Step2;