import React,{useState} from 'react';
import * as S from './styled';
import { Email } from '@styled-icons/entypo/Email';
import { Paragraph, Bolder } from '../../styles/elements';
import FormButton from '../QuizForm/FormButton';

const Step1 = ({state, setValues}) => {
    const [email, setEmail] = useState(() => state.email ? state.email : '');

    return (
        <S.StepWrapper style={{alignItems: 'center'}}>
            <Paragraph>Olá, Esbelta!</Paragraph>
            <Paragraph>Você já deve ter ouvido a expressão <span style={{fontWeight: '700'}}>"Somos o que comemos"</span>, certo?</Paragraph>

            <Paragraph>Mas talvez o que você não saiba é o quanto essa expressão é verdadeira!</Paragraph>

            <Paragraph>A maioria dos problemas físicos e de saúde que enfrentamos ao longo da nossa vida, é causado ou agravado por uma má alimentação, e muitas vezes nem nos damos conta disso.</Paragraph>
            <Paragraph>Até mesmo dores de cabeça, enxaquecas, cansaço, distensão abdominal, excesso de gases, constipação, ansiedade, gastrite e azia - são, na maioria das vezes, reflexo de como estamos nos alimentando.</Paragraph>

            <Paragraph>Mas não apenas isso. Muitas doenças graves poderiam ser evitadas com uma alimentação adequada.</Paragraph>

            <Paragraph>O próprio envelhecimento, que é o principal fator de risco para doenças como: <br/> câncer, doenças cardiovasculares, osteoporose, alzheimer, problemas de visão - entre tantas outras - 
            pode ser atenuado com uma boa alimentação, promovendo uma maior regeneração celular,
            e assim podemos estender nossos anos saudáveis em 15, 20 ou até 30 anos a mais, se cuidarmos desse fator que está tão presente no nosso dia a dia.</Paragraph>

            <Paragraph>"Mas nutri, eu estou aqui para emagrecer, eu quero é ficar bonita!"</Paragraph>
            <Paragraph>Pois saiba, minha cara, que a estética é apenas mais uma consequência do estado da sua saúde. 
            Um peso adequado, uma cintura mais fina, uma pele mais bonita, são os frutos gerados por uma árvore que possui as raízes bem nutridas.</Paragraph>

            <Paragraph>Mas, se você está aqui comigo, lendo isso, fique tranquila, pois você já está no caminho certo para ter uma vida mais saudável e longeva, e é claro, com mais beleza.</Paragraph>

            <Paragraph>Preparei um questionário para te conhecer melhor e para que a gente consiga tornar esse método o mais personalizado possível para a sua vida.</Paragraph>

            <Paragraph>Bora lá? Responda com sinceridade, ok?</Paragraph>

            <Paragraph>Insira seu email de login para começar:</Paragraph>

            <S.FormInput style={{marginTop: '2rem'}}>
                    <Email className="icon" />
                    <input
                        type="email"
                        min="10"
                        max="340"
                        placeholder="Email de login"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}

                    />
            </S.FormInput>
            <FormButton text='Começar' 
                type="next" 
                onClick={(e) => {
                    e.preventDefault();
                    setValues({'email': email})} 
                }
            />


        </S.StepWrapper>
    )
}

export default Step1;