import React, { useState } from 'react';
import * as S from './styled';
import RadioGroup from '../QuizForm/RadioGroup';
import FormButton from '../QuizForm/FormButton';

import { 
    YES_NO_SOMETIMES,
    FREQ_WEEK,
    FREQ_DAY,
    NIVEL_ATIVIDADE
} from './util/formOptions';

const Step4 = ({state, setValues}) => {
    const [stepValues, setStepValues] = useState(() => ({
        'refeicoes': state.refeicoes ? state.refeicoes : '',
        'desjejum': state.desjejum ? state.desjejum : '',
        'horarios': state.horarios ? state.horarios : '',
        'planeja': state.planeja ? state.planeja : '',
        'exercicios': state.exercicios ? state.exercicios : '',
        'activity': state.activity ? state.activity : ''
    }));
        
    const onChangeValue = ({name, value}) => {
        setStepValues({...stepValues, [name]: value})
    } 

    return (
        <S.StepWrapper>
            <RadioGroup 
                name="refeicoes"
                text="Quantas refeições por dia você costuma fazer?" 
                options={FREQ_DAY}
                checked={stepValues.refeicoes}
                onChangeValue={onChangeValue}
            />            
            <RadioGroup 
                name="desjejum"
                text="Você faz o desjejum (café da manhã) ao acordar?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.desjejum}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="horarios"
                text="Você costuma fazer suas refeições sempre nos mesmos horários?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.horarios}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="planeja"
                text="Você planeja suas refeições?" 
                options={YES_NO_SOMETIMES}
                checked={stepValues.planeja}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="exercicios"
                text="Você pratica exercícios, com que frequência?" 
                options={FREQ_WEEK}
                checked={stepValues.exercicios}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="activity"
                text="Qual é o seu nível de atividade?" 
                options={NIVEL_ATIVIDADE}
                checked={stepValues.activity}
                onChangeValue={onChangeValue}
            />
            <FormButton 
                text="Avançar" 
                type="next" 
                onClick={(e) => {
                    e.preventDefault();
                    setValues(stepValues)
                }} 
            />
        </S.StepWrapper>
    )
}

export default Step4;