import React, {useState} from 'react';
import * as S from './styled';
import { BirthdayCake } from '@styled-icons/fa-solid/BirthdayCake';
import { Height } from '@styled-icons/material/Height';
import { Weight } from '@styled-icons/fa-solid/Weight';
import { TargetArrow } from '@styled-icons/fluentui-system-regular/TargetArrow';
import FormButton from '../QuizForm/FormButton';

const Step5 = ({state, setValues}) => {
    const [stepValues, setStepValues] = useState(() => ({
        'age': state.age ? state.age : '',
        'height': state.height ? state.height : '',
        'weight': state.weight ? state.weight : '',
        'target': state.target ? state.target : ''
    }));
        
    const onChangeValue = ({name, value}) => {
        setStepValues({...stepValues, [name]: value})
    }

    return (
        <S.StepWrapper center>
            <S.FormInput>
                <BirthdayCake className="icon"/>
                <input
                    type="number"
                    min="14"
                    max="100"
                    placeholder="Idade"
                    name="age"
                    value={stepValues.age}
                    onChange={(e) => onChangeValue(e.target)}
                    required="required"
                />
            </S.FormInput>
            <S.FormInput>
                <Height className="icon"/>
                <input
                    type="number"
                    name="height"
                    min="130"
                    max="242"
                    placeholder="Altura (cm)"
                    value={stepValues.height}
                    onChange={(e) => onChangeValue(e.target)}
                    required="required"
                />
            </S.FormInput>
            <S.FormInput>
                <Weight className="icon"/>
                <input
                    type="number"
                    name="weight"
                    min="40"
                    max="180"
                    value={stepValues.weight}
                    placeholder="Peso (Kg)"
                    onChange={(e) => onChangeValue(e.target)}
                    required="required"
                />
            </S.FormInput>
            <S.FormInput>
                <TargetArrow className="icon"/>
                <input
                    type="number"
                    name="target"
                    value={stepValues.target}
                    placeholder="Peso Alvo (Kg)"
                    min="40"
                    max="180"
                    onChange={(e) => onChangeValue(e.target)}
                    required="required"
                />
            </S.FormInput>
            <FormButton 
                text="Calcular" 
                type="submit" 
                onClick={(e) => {
                    e.preventDefault();
                    setValues(stepValues)
                }} 
            />
        </S.StepWrapper>
    );
}
export default Step5;