import React, { useRef, useState } from "react"
import * as S from "./styled"
import { useCalculateResults, sendMail } from "./util/calculadoraHooks"
import Loader from "../Loader"
import { createImages } from "../../pdfCreation"
import FormButton from "../QuizForm/FormButton"

import {
  YES_NO_SOMETIMES,
  FREQUENCIA,
  FREQ_YEAR,
  FREQ_WEEK,
  FREQ_DAY,
  HABITO_INTESTINAL,
  getTextByValue,
} from "./util/formOptions"

export const definePDFImages = async (ref1, ref2, ref3, ref4) => {
  let pdfImages = []
  await createImages([ref1, ref2, ref3, ref4]).then(imgs => (pdfImages = imgs))

  return pdfImages
}

const ResultPage = ({ state }) => {
  const results = useCalculateResults(state)
  const [sentMessage, setSentMessage] = useState(null)
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const {
    total,
    cho,
    lip,
    ptn,
    water,
    weightToLose,
    time,
    detox,
    contactId,
  } = results

  const onSubmitValues = async e => {
    e.preventDefault()
    try {
      const response = await sendMail(state.email, contactId)
      setSentMessage(response)
    } catch (err) {
      setSentMessage(err)
    }
  }

  return results && results.total ? (
    <S.ResultsWrapper>
      <S.ShowResultsInfo id="results" ref={ref1}>
        <S.ResultsInfoTitle>Resultados Calculados</S.ResultsInfoTitle>
        <S.Box>
          <S.BoxHeader>Peso que deseja perder</S.BoxHeader>
          <S.BoxValue>{weightToLose} kg</S.BoxValue>
        </S.Box>
        <S.Box>
          <S.BoxHeader>Tempo para alcançar o objetivo</S.BoxHeader>
          <S.BoxValue>{time} meses</S.BoxValue>
        </S.Box>
        <S.Box>
          <S.BoxHeader>Precisa iniciar pelo Detox</S.BoxHeader>
          <S.BoxValue>{detox}</S.BoxValue>
        </S.Box>
        <S.Box>
          <S.BoxHeader>Sua Dieta deve ser de</S.BoxHeader>
          <S.BoxValue>{total} Kcal</S.BoxValue>
        </S.Box>
        {/* <S.Box><S.BoxHeader>Composição nutricional da Dieta</S.BoxHeader>
                    <S.BoxMultipleValues>
                        <span>Gorduras: {lip}g </span>
                        <span>Carboidratos: {cho}g </span>
                        <span>Proteínas: {ptn}g</span>
                    </S.BoxMultipleValues>
                </S.Box> */}
        <S.Box>
          <S.BoxHeader>Ingestão de água por dia</S.BoxHeader>
          <S.BoxValue>{water}L</S.BoxValue>
        </S.Box>
      </S.ShowResultsInfo>

      <S.ShowResultsInfo id="saude" ref={ref2}>
        <S.ResultsInfoTitle>Respostas: Como Anda Sua Saúde</S.ResultsInfoTitle>
        <S.InfoGroup>
          <S.InfoLabel>Se sente cansada já no início do dia </S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.cansada)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Tem dificuldade para dormir</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.dificil_dormir)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Sofre de ansiedade</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.ansiedade)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Tem episódios de compulsão alimentar</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.compulsao)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Sente dores de cabeça ou enxaqueca</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.enxaqueca)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Fica doente com frequência</S.InfoLabel>
          <S.InfoValue>{getTextByValue(FREQ_YEAR, state.doente)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Hábitos intestinais</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(HABITO_INTESTINAL, state.intestino)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Sofre com excesso de gases</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.gases)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Tem azia</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.azia)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Tem distensão abdominal</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.distensao)}
          </S.InfoValue>
        </S.InfoGroup>
      </S.ShowResultsInfo>
      <S.ShowResultsInfo id="food" ref={ref3}>
        <S.ResultsInfoTitle>
          Respostas: Como Tem Sido Sua Alimentação Nos Últimos Meses
        </S.ResultsInfoTitle>
        <S.InfoGroup>
          <S.InfoLabel>Consome farináceos</S.InfoLabel>
          <S.InfoValue>{getTextByValue(FREQUENCIA, state.farinha)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Consome açúcar</S.InfoLabel>
          <S.InfoValue>{getTextByValue(FREQUENCIA, state.acucar)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Bebe alcoól</S.InfoLabel>
          <S.InfoValue>{getTextByValue(FREQUENCIA, state.alcool)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Consome ultraprocessados</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(FREQUENCIA, state.ultraprocessados)}
          </S.InfoValue>
        </S.InfoGroup>
      </S.ShowResultsInfo>
      <S.ShowResultsInfo id="rotina" ref={ref4}>
        <S.ResultsInfoTitle>Respostas: Como é a Sua Rotina</S.ResultsInfoTitle>
        <S.InfoGroup>
          <S.InfoLabel>Quantas refeições por dia costuma fazer</S.InfoLabel>
          <S.InfoValue>{getTextByValue(FREQ_DAY, state.refeicoes)}</S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Faz o desjejum ao acordar</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.desjejum)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>
            Costuma fazer as refeições nos mesmos horários
          </S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.horarios)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Planeja as refeições</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(YES_NO_SOMETIMES, state.planeja)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Pratica exercícios</S.InfoLabel>
          <S.InfoValue>
            {getTextByValue(FREQ_WEEK, state.exercicios)}
          </S.InfoValue>
        </S.InfoGroup>
        <S.InfoGroup>
          <S.InfoLabel>Seu nível de atividade </S.InfoLabel>
          <S.InfoValue>
            {state.activity === "1"
              ? "Pouco Ativa"
              : state.activity === "2"
              ? "Moderado"
              : "Muito ativa"}
          </S.InfoValue>
        </S.InfoGroup>
      </S.ShowResultsInfo>
      <FormButton text="Enviar para meu email" onClick={onSubmitValues} />
      {sentMessage && <S.SentMessage>{sentMessage}</S.SentMessage>}

      {/* <S.CreatePDFWrapper>
                {
                images &&
                <PDFDownloadLink 
                    document={<CreatePDF images={images} />} 
                    fileName={`Resultados ${state.email}.pdf`}>
                        {({ blob, url, loading, error }) => {
                            
                            return (
                                loading ? 
                                <span>Carregando pdf...</span> : 
                                <FormButton type="download">Salvar Resultado em PDF </FormButton>
                                )
                            }}
                </PDFDownloadLink>
                }                    
            </S.CreatePDFWrapper> */}
    </S.ResultsWrapper>
  ) : (
    <Loader />
  )
}

export default ResultPage
