import React, { useState } from 'react';
import * as S from './styled';
import RadioGroup from '../QuizForm/RadioGroup';
import FormButton from '../QuizForm/FormButton';

import { 
    FREQ_WEEK,
    FREQUENCIA,
} from './util/formOptions';

const Step3 = ({state, setValues}) => {
    const [stepValues, setStepValues] = useState(() => ({
        'farinha': state.farinha ? state.farinha : '',
        'acucar': state.acucar ? state.acucar : '',
        'alcool': state.alcool ? state.alcool : '',
        'ultraprocessados': state.ultraprocessados ? state.ultraprocessados : '',
    }));

    
    const onChangeValue = ({name, value}) => {
        setStepValues({...stepValues, [name]: value})
    } 

    return (
        <S.StepWrapper>
            <RadioGroup 
                name="farinha"
                text="Consome farináceos? com que frequência? (pães, massas, bolos, etc)" 
                options={FREQUENCIA}
                checked={stepValues.farinha}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="acucar"
                text="Consome alimentos e bebidas com açúcar? com que frequência?" 
                options={FREQUENCIA}
                checked={stepValues.acucar}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="alcool"
                text="Bebe álcool? Com que frequência?" 
                options={FREQ_WEEK}
                checked={stepValues.alcool}
                onChangeValue={onChangeValue}
            />
            <RadioGroup 
                name="ultraprocessados"
                text="Costuma ingerir ultraprocessados? (bolacha recheada, salgadinhos, nuggets, etc)" 
                options={FREQUENCIA}
                checked={stepValues.ultraprocessados}
                onChangeValue={onChangeValue}
            />

            <FormButton 
                text="Avançar" 
                type="next" 
                onClick={(e) => {
                    e.preventDefault();
                    setValues(stepValues)
                }} 
            />
        </S.StepWrapper>
    )
}
export default Step3;