import React from 'react';
import QuizForm from '../QuizForm';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import ResultPage from './ResultPage';

const Calculadora = () => (
        <QuizForm>
            <Step1 title="Definindo a Sua Estratégia"/>
            <Step2 title="Vamos Avaliar Como Anda Sua Saúde?" />
            <Step3 title="Como Tem Sido Sua Alimentação Nos Últimos Meses?" />
            <Step4 title="Vamos Ver Como é a Sua Rotina?" />
            <Step5 title="Suas Informações Pessoais"/>
            <ResultPage title="Aqui Está o Resultado Da Sua Avaliação"/> 
        </QuizForm>
    )

export default Calculadora;