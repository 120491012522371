import styled from 'styled-components';
import media from 'styled-media-query';

export const StepWrapper = styled.div`
    /* grid-row: 2/5;
    grid-column: 1/-1; */
    display: flex;
    flex-direction: column;
    align-items: ${props => props.center ? 'center':'flex-start'};
    justify-content: center;
    font-size: 1.1rem;
    color: white;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;

    ${media.lessThan("medium")`
        margin: 0.5rem 1.5rem;
    `}

    span {
        margin: 2rem;
    }
`


export const ButtonWrapper = styled.label`
    cursor: pointer;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    opacity: 0.6;
    border-radius: 50%;
    color: white;
    font-size: 1.2rem;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    margin: 1rem 4rem;
    max-width: 100%; 
    transition: box-shadow 1s, color 1s, opacity 1s;

    &:hover, .checked {
        box-shadow: 2px 2px 14px rgb(0 0 0 / 40%);
        color: rgba(255, 255, 255, 0.6);
        opacity: 1;
    }

    input {
        appearance: none;
        outline: none;
        border-radius: 0;
        display: none;
        margin: 0;
        padding: 0;

        
    }

    input::after {
        display: inline-block;
        margin: 60px;
        border-radius: 50%;
        color: white;
        transition: box-shadow 1s, color 1s, opacity 1s;
        opacity: 0.6;
        vertical-align: middle;
        cursor: pointer;
    }

    input:checked:after, input:hover:after {
        box-shadow: 2px 2px 14px rgb(0 0 0 / 40%);
        color: rgba(255, 255, 255, 0.6);
        opacity: 1;
    }

    div.image {
        z-index: -1;
    }

    div.text { 
        margin-top: -7rem; 
        z-index: 10;
    }

    ${media.lessThan("medium")`
        max-width: 50%; 
        opacity: 1;

        div.text {
            margin-top: -4.5rem;
        }
    `}
    
`

export const MaleButtonWrapper = styled(ButtonWrapper)`
    width: 269px;
    height: 268px;
    content: ' ';
    margin: 2rem;
`

export const FemaleButtonWrapper = styled(ButtonWrapper)`
    width: 269px;
    height: 267px;
    content: ' ';
    margin: 2rem;
`

export const wraptest = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

`

export const ActivityGridContainer = styled.div`
    display: flex;
    flex-direction: row;

    ${media.lessThan("medium")`
        flex-direction: column;        
    `}
    /* display: grid;
    grid-template-columns: repeat(3, minmax(0,1fr)); */
`

export const ActivityButtonWrapper = styled(ButtonWrapper)`
    border-radius: 20px;
    margin: 1rem 2rem;
    display:flex;
    flex-direction: column;
    border:  1px solid var(--orange) !important;
    border-radius: 1rem;
    box-shadow: 2px 2px 14px rgb(0 0 0 / 40%);


    /* .image {
        width: '250px' !important;
        height: '250px';
    }

    ${media.lessThan("medium")`
        .image {
            width: '125px';
            height: '125px';
        }
    `} */

`
export const ActivityDescription = styled.div`
    text-align: center;
    padding: 0 1rem 1rem;

    h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin: 10px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
    }
`

export const FormInput = styled.div`
    position: relative;
    display: inline-block;
    margin: 5px 0;

    .icon {
        height: 25px;
        width: 25px;
        color: var(--orange);
        margin-right: 10px;
    }

    input {
        line-height: 25px;
        background: transparent;
        border: none;
        font-size: 18px;
        border-bottom: 1px solid var(--orange);
        padding: 5px 16px;
        color: white;
        border-radius: 0;
        width: 250px;
    }

    input::placeholder {
        color: #bfbfbf;
    }

    input:hover, input:active {
        border: 1px solid var(--orange);
    }

    button {
        margin: 2rem auto;
        padding: 1rem 2rem;
        border-radius: 20px;
        color: white;
        width: 250px;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 500;
        border: 1px solid var(--pink);
        background-color: transparent;
        cursor: pointer;
        box-shadow: 2px 2px 14px rgb(0 0 0 / 40%);
        transition: background-color 1s;
    }

    button:hover {
        background-color: var(--pink);//linear-gradient(90deg, #0284fe 0%, #0df5d2 100%);
    }
`

export const ResultsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;

    ${media.lessThan("medium")`
        margin: 0.5rem 1rem;
    `}
`

export const InfoTitle = styled.h2`
    font-size: 1.5rem;
    color: white;
    font-weight: 600;
    font-family: 'Roboto', sans-serif;
    margin: 3rem 0 1rem 5px;
    text-transform: uppercase;

    ${media.lessThan("medium")`
        font-size: 1.2rem;
    `}
`

export const ShowResultsInfo = styled.div`
    background: white;
    padding: 1rem;
    border: 1px solid var(--orange);
    border-radius: 10px;
    box-shadow: 6px 6px 14px rgb(0 0 0 / 60%);
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 1.2rem;
    width: 100%;
    margin-bottom: 2rem;
    /* justify-items: start; */

    ${media.lessThan("medium")`
        grid-template-columns: 1fr;        
    `}
    
`

export const ResultsInfoTitle = styled(InfoTitle)`
    grid-column: span 2; 
    color: var(--purple); 
    margin: 1rem auto;

    ${media.lessThan("medium")`
        grid-column: span 1;       
    `}
`

export const InfoGroup = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin: 10px 1rem;
`

export const InfoLabel = styled.h3`
    grid-row: 1/2;
    font-size: 0.8rem;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--grayTexts);
`

export const InfoValue = styled.p`
    grid-row: 2/3;
    font-size: 1rem;
    font-weight: 600;
    color: var(--postColor);
`

export const ResultBoxes = styled.div`
    grid-row: 2/5;
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-gap: 2rem;
    margin: 0 auto;

    ${media.lessThan("medium")`
        padding: 1rem 2rem;
    `}

`
export const Box = styled.div`
    grid-column: span 1;
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem 2rem;
    background: var(--purple);
    border-radius: 8px;
    box-shadow: 2px 2px 14px rgb(0 0 0 / 40%);

    ${media.lessThan("medium")`
        padding:  1rem;
        width: 100%;
    `}
`

export const BoxHeader = styled.h2`
    grid-row: 1/2;
    color: white;
    justify-self: start;
    font-size:  1.2rem;
    
    ${media.lessThan("medium")`
        font-size:  1rem;
    `}
`

export const BoxValue = styled.div`
    grid-row: 2/3;
    color: var(--yellow);
    font-size: 2.2rem;
    place-self: center;
    padding: 2rem;
    
    ${media.lessThan("medium")`
        font-size: 1.6rem;
        padding: 1rem;
    `}
`

export const BoxMultipleValues = styled(BoxValue)`
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    
    span + span {
        margin-top: 10px;
    }

    ${media.lessThan("medium")`
        font-size: 1.2rem;
    `}
`

export const CreatePDFWrapper = styled.div`
    span {
        font-size: 1rem;
        color: var(--orange);
    }
`

export const SentMessage = styled.div`
    margin: 1rem auto;
    color: var(--yellow);
    font-size: 1rem;

    .error {
        color: red;
    }
`