import React from 'react';
import GlobalStyles from '../styles/global';
import QuizLayout from '../components/QuizLayout';
import Calculadora from '../components/Calculadora';
// import * as S from '../styles/quiz';
import { Provider as FormProvider } from '../context/FormContext';


const CalculadoraPage = () => {

    return (
        <QuizLayout>
            <GlobalStyles />
            <FormProvider>
                <Calculadora />
            </FormProvider>
        </QuizLayout>
    )
}

export default CalculadoraPage;
